import { config } from "@config/config";
import { IconSizeEnum } from "@config/icons";
import { useTranslation } from "@translations/use-translation";
import { Button } from "@ui/Button";
import { CircleButton } from "@ui/CircleButton";
import { FlexBox } from "@ui/FlexBox";
import { Icon } from "@ui/Icon";
import { TextLarge } from "@ui/Text";
import { CssHelper } from "@utils/CssHelper";
import { memo } from "react";
import { SocialButtonType } from "./types";

interface FacebookLoginButtonsProps {
    onlyType?: SocialButtonType;
    title?: string;
}

const FacebookLoginButtonsComponent = (props: FacebookLoginButtonsProps): JSX.Element => {
    const { t } = useTranslation();
    const { onlyType, title } = props;
    return (
        <>
            {onlyType !== "circle" && (
                <FlexBox
                    flexDirection="column"
                    permanentlyHidden={!onlyType ? { condition: true, from: "lg" } : undefined}
                >
                    <Button
                        data-testid="facebook-login-btn"
                        href={`${config.FRONTEND_URL}/api/auth/oidc/facebook/login`}
                        kind="white"
                        mt={12}
                        startComponent={<Icon color="facebookBlue" mb={2} name="facebook" size={IconSizeEnum.medium} />}
                        variant="text"
                    >
                        {title ?? t("component-auth-login-buttons-facebook-login:button")}
                    </Button>
                </FlexBox>
            )}
            {onlyType !== "basic" && (
                <FlexBox
                    alignItems="center"
                    flexDirection="column"
                    permanentlyHidden={!onlyType ? { condition: true, to: "lg" } : undefined}
                >
                    <CircleButton
                        data-testid="facebook-login-btn"
                        href={`${config.FRONTEND_URL}/api/auth/oidc/facebook/login`}
                        iconColor="facebookBlue"
                        name="facebook"
                        size="size56"
                        variant="white"
                    />
                    <TextLarge mt={CssHelper.spacing(1)} textColor="textMuted" textFontWeight="medium">
                        {title ?? t("component-auth-login-buttons-facebook-login:text")}
                    </TextLarge>
                </FlexBox>
            )}
        </>
    );
};

export const FacebookLoginButtons = memo(FacebookLoginButtonsComponent);

FacebookLoginButtons.displayName = "FacebookLoginButtons";
