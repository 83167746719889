import * as GQL from "@gql-schema";
import { AuthResultFragment, LoginMutationVariables } from "@gql-schema";
import { ExecutionResult } from "graphql/execution";
import { useCallback } from "react";

// INFO: THIS HOOK IS COMPLETED AND USED
type HookCallback = (variables: LoginMutationVariables) => Promise<ExecutionResult<{ login: AuthResultFragment }>>;
type Hook = () => [HookCallback, { loading: boolean }];

export const useLoginMutation: Hook = () => {
    const [loginMutation, { loading }] = GQL.useLoginMutation();

    const mutation = useCallback(
        (variables: LoginMutationVariables) => {
            return loginMutation({
                variables,
                refetchQueries: () => "active",
            });
        },
        [loginMutation],
    );

    return [mutation, { loading }];
};
