import { routeToUrl } from "@app-routes";
import { useTranslation } from "@translations/use-translation";
import { FlexBox } from "@ui/FlexBox";
import { Link } from "@ui/Link";
import { Span, TextLarge } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { FC, memo } from "react";

interface LoginCustomHeaderContentProps {
    redirUrl?: string;
}

export const LoginCustomHeaderContentComponent: FC<LoginCustomHeaderContentProps> = (props) => {
    const { t } = useTranslation();
    const { redirUrl } = props;
    return (
        <FlexBox alignItems="center" justifyContent="flex-end" mr={spacing(3)}>
            <TextLarge>
                <Span mr={spacing(1)} visuallyHidden={{ condition: true, to: "sm" }}>
                    {t("component-auth-login-custom-header-content:span")}{" "}
                </Span>
                <Link
                    href={routeToUrl("auth-zone/registration", redirUrl ? { redirUrl } : {})}
                    textColor="secondary"
                    textFontWeight="semiBold"
                >
                    {t("component-auth-login-custom-header-content:link")}
                </Link>
            </TextLarge>
        </FlexBox>
    );
};

export const LoginCustomHeaderContent = memo(LoginCustomHeaderContentComponent);

LoginCustomHeaderContent.displayName = "LoginCustomHeaderContent";
