import { routeToUrl } from "@app-routes";
import { useTranslation } from "@translations/use-translation";
import { Button } from "@ui/Button";
import { FlexBox } from "@ui/FlexBox";
import { Link } from "@ui/Link";
import { ReduxFieldInput, WrappedFieldInput } from "@ui/ReduxFieldWrapper/ReduxFieldInput";
import { TextMedium } from "@ui/Text";
import { CssHelper } from "@utils/CssHelper";
import { requiredField } from "@utils/formHelper";
import { FC } from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import { useEmailValidator, usePasswordValidator } from "../authHelper";

interface IProps {
    mutationLoading?: boolean;
    hideEmail?: boolean;
    onSubmit: (data: ILoginForm) => Promise<void> | undefined;
    redirUrl?: string;
}

export interface ILoginForm {
    email: string;
    password: string;
}

type Props = IProps & InjectedFormProps<ILoginForm, IProps>;

const LoginForm: FC<Props> = (props) => {
    const { t } = useTranslation();
    const { handleSubmit, mutationLoading, onSubmit, redirUrl, submitting, hideEmail } = props;

    const emailValidator = useEmailValidator();
    const passwordValidator = usePasswordValidator();

    return (
        <form onSubmit={handleSubmit} method="post">
            <FlexBox flexDirection="column" justifyContent="stretch">
                {!hideEmail && (
                    <ReduxFieldInput
                        data-testid={"login-form-email"}
                        name="email"
                        component={WrappedFieldInput}
                        placeholder={t("component-auth-login-form:email.placeholder")}
                        type="email"
                        label="E-mail"
                        validate={[requiredField, emailValidator]}
                        autoComplete="username email"
                        controlProps={{
                            mb: 20,
                        }}
                    />
                )}
                <ReduxFieldInput
                    data-testid={"login-form-password"}
                    name="password"
                    component={WrappedFieldInput}
                    placeholder={t("component-auth-login-form:password.placeholder")}
                    type="password"
                    label={t("component-auth-login-form:password.label")}
                    validate={[passwordValidator]}
                    autoComplete="current-password"
                />
                <TextMedium mt={CssHelper.spacing(1)} textAlign="right" textColor="textMuted">
                    {t("component-auth-login-form:text")}{" "}
                    <Link
                        textColor="primary"
                        href={routeToUrl("auth-zone/forgotten-password-request", redirUrl ? { redirUrl } : {})}
                    >
                        {t("component-auth-login-form:link")}
                    </Link>
                    .
                </TextMedium>
                <Button
                    data-testid={"login-form-submit"}
                    loading={submitting || mutationLoading}
                    mb={12}
                    mt={32}
                    onClick={handleSubmit(onSubmit)}
                    size="large"
                    type="submit"
                    variant="primary"
                >
                    {t("component-auth-login-form:button")}
                </Button>
            </FlexBox>
        </form>
    );
};

const FORM_NAME = "loginForm";

export default reduxForm<ILoginForm, IProps, string>({ form: FORM_NAME })(LoginForm);
