import { AddressFragment, SmartAddressFragment } from "@gql-schema";

export const formatAddress = (address: AddressFragment): string | undefined => {
    const addressArray = [];

    if (!address) {
        return undefined;
    }

    if (address.street) {
        addressArray.push(address.street);
    }
    if (address.city) {
        addressArray.push(address.city);
    }
    if (address.zip) {
        addressArray.push(address.zip);
    }

    return addressArray.filter((v) => !!v).join(", ");
};

const humanReadableSmartAddress = (
    address: SmartAddressFragment,
): [string | null | undefined, string | null | undefined] => {
    const { level, obecNazev, momcNazev, mopNazev, castObceNazev, uliceNazev, cisloDomovni, cisloOrientacni } = address;

    const cisloOrientacniSuffix = cisloOrientacni ? `/${cisloOrientacni}` : "";
    const cisloOrientacniZnak = address.cisloOrientacniZnak || "";

    switch (level) {
        case 6:
            return [obecNazev, undefined];
        case 5:
            return [`${obecNazev}, ${momcNazev}`, undefined];
        case 4:
            return [`${obecNazev}, ${mopNazev}`, undefined];
        case 3:
            return [`${obecNazev}, ${castObceNazev}`, undefined];
        case 2:
            return [uliceNazev, `${obecNazev}, ${castObceNazev}`];
        case 1:
            if (uliceNazev !== null) {
                return [
                    `${uliceNazev} ${cisloDomovni}${cisloOrientacniSuffix}${cisloOrientacniZnak}`,
                    obecNazev === castObceNazev ? obecNazev : `${obecNazev}, ${castObceNazev}`,
                ];
            }

            return [`${obecNazev} ${cisloDomovni}${cisloOrientacniSuffix}${cisloOrientacniZnak}`, `${castObceNazev}`];
        default:
            return ["", ""];
    }
};

const formatSmartAddress = (address?: SmartAddressFragment): string => {
    return address
        ? humanReadableSmartAddress(address)
              .filter((v) => !!v)
              .join(", ")
        : "";
};

export const AddressFormatter = {
    formatAddress,
    formatSmartAddress,
    humanReadableSmartAddress,
};
