import { routeToUrl } from "@app-routes";
import { NextPage } from "@app-types/next";
import LoginComponent from "@components/AuthZone/Login/LoginComponent";
import { LoginCustomHeaderContentComponent } from "@components/AuthZone/Login/LoginCustomHeaderContent";
import { LoginSocial } from "@components/AuthZone/Login/LoginSocialComponent";
import { LoginWelcomeBack } from "@components/AuthZone/Login/LoginWelcomeBack";
import { RedirectMessageBox } from "@components/AuthZone/RedirectMessageBox";
import {
    getLastLoggedEmailFromCookie,
    getLastLoggedUserAvatarFromCookie,
    getLastLoggedUserNameFromCookie,
    getLastLoginMethodFromCookie,
} from "@components/AuthZone/authHelper";
import { defaultMetaTagData } from "@config/defaultMetaTagData";
import { useGetRedirectData } from "@hooks/useGetClubPublicData";
import { useTranslation } from "@translations/use-translation";
import { RegistrationScreen } from "@ui/Layout/RegistrationScreen";
import { Link } from "@ui/Link";
import { TextH1, TextLarge, TextMedium } from "@ui/Text";
import { CssHelper } from "@utils/CssHelper";
import { UrlHelper } from "@utils/UrlHelper";
import { spacing } from "@uxf/styles/units/spacing";

interface PageProps {
    lastLoggedUserName: string;
    lastLoggedEmail: string;
    lastLoginMethod: string;
    lastLoggedUserAvatar: string;
}

const Login: NextPage<PageProps> = (props) => {
    const { t } = useTranslation();
    const { clubPublicData, redirUrl } = useGetRedirectData();
    const { lastLoggedUserName, lastLoggedEmail, lastLoginMethod, lastLoggedUserAvatar } = props;

    const isLastLoggedUser = !!lastLoggedUserName && !!lastLoggedEmail && !!lastLoginMethod;

    return (
        <RegistrationScreen
            customHeaderContent={<LoginCustomHeaderContentComponent redirUrl={redirUrl} />}
            hideNavBar
            hideTools
            logoDisabled={!!redirUrl}
            ogData={{
                ogTitle: t("page-auth-login:og.title"),
                description: t("page-auth-login:og.description", { ogTitle: defaultMetaTagData.ogTitle }),
            }}
            pageName="Login"
            redirectMessageBox={
                <RedirectMessageBox
                    bkgImage="/static/images/onboarding-city.jpg"
                    clubName={clubPublicData?.name ?? ""}
                    imageRootProps={{
                        title: t("page-auth-login:image-root-props.title"),
                        buttons: [
                            {
                                label: t("page-auth-login:image-root-props.button-title"),
                                href: routeToUrl("auth-zone/registration", {}),
                            },
                        ],
                    }}
                    redirUrl={redirUrl}
                    variant="primary"
                />
            }
            title={t("page-auth-login:title")}
            canonicalUrl={UrlHelper.getAbsoluteUrl("auth-zone/login", {})}
        >
            {isLastLoggedUser ? (
                <LoginWelcomeBack
                    lastLoggedUserName={lastLoggedUserName}
                    lastLoggedEmail={lastLoggedEmail}
                    lastLoginMethod={lastLoginMethod}
                    lastLoggedUserAvatar={lastLoggedUserAvatar}
                />
            ) : (
                <>
                    <TextH1 mb={spacing(4)} textAlign="center" textVariant={["h2", null, null, "h1"]}>
                        {t("page-auth-login:h1")}
                    </TextH1>
                    <LoginSocial redirectUrl={redirUrl} />
                    <TextLarge
                        mb={spacing(3)}
                        mt={spacing(5)}
                        textAlign="center"
                        textColor="textMuted"
                        textFontWeight="medium"
                    >
                        {t("page-auth-login:or")}
                    </TextLarge>
                    <LoginComponent />
                    <TextMedium mt={CssHelper.spacing(1)} textAlign="center" textColor="textMuted">
                        <Link href={routeToUrl("footer-zone/data-protection")} mr={CssHelper.spacing(1)}>
                            {t("page-auth-login:data-protection")}
                        </Link>
                        |
                        <Link
                            href={routeToUrl("footer-zone/memorandum")}
                            mr={CssHelper.spacing(1)}
                            ml={CssHelper.spacing(1)}
                        >
                            {t("page-auth-login:memorandum")}
                        </Link>
                        |
                        <Link
                            href={UrlHelper.getTermsAndConditionsUrl()}
                            mr={CssHelper.spacing(1)}
                            ml={CssHelper.spacing(1)}
                        >
                            {t("page-auth-login:terms-and-conditions")}
                        </Link>
                    </TextMedium>
                </>
            )}
        </RegistrationScreen>
    );
};

Login.getInitialProps = async (ctx) => {
    await ctx.apolloClient.clearStore();
    const lastLoggedUserName = getLastLoggedUserNameFromCookie(ctx);
    const lastLoggedEmail = getLastLoggedEmailFromCookie(ctx);
    const lastLoginMethod = getLastLoginMethodFromCookie(ctx);
    const lastLoggedUserAvatar = getLastLoggedUserAvatarFromCookie(ctx);

    return { lastLoggedUserName, lastLoggedEmail, lastLoginMethod, lastLoggedUserAvatar };
};

export default Login;
