import { FieldInput } from "@ui/Field/FieldInput/FieldInput";
import { FC } from "react";
import { GenericField, Field as ReduxFormField, WrappedFieldProps } from "redux-form";
import { IWrappedFieldInputProps } from "./types";

export const WrappedFieldInput: FC<IWrappedFieldInputProps & WrappedFieldProps> = (props) => {
    const {
        controlProps,
        CustomErrorComponent,
        input: { name, ...restInput },
        inputProps,
        hideErrorMessage,
        hideLabel,
        label,
        meta: { error, form, invalid, touched },
        ...restProps
    } = props;

    const inputId = `${form}__field-input--${name}`;
    const hasError = touched ? invalid : undefined;

    const wrapperProps = {
        CustomErrorComponent,
        hideErrorMessage,
        hideLabel,
        id: inputId,
        label,
    };

    return (
        <FieldInput
            controlProps={controlProps}
            formElementProps={{
                errorMessage: error,
                invalid: hasError,
                name,
                ...(restInput ?? {}),
                ...restProps,
            }}
            inputProps={inputProps}
            {...wrapperProps}
        />
    );
};

/** @deprecated use @form/TextField */
export const ReduxFieldInput = ReduxFormField as new () => GenericField<IWrappedFieldInputProps>;
