import { UxfNextPageContext } from "@app-types/next";
import { isBrowser } from "./isBrowser";

export default class Cookie {
    private context: any;

    constructor(context: UxfNextPageContext | null = null) {
        this.context = context;
    }

    /**
     * Return cookie by given name
     */
    public getCookie(cookieName: string): string {
        if (isBrowser) {
            const name = cookieName + "=";
            const decodedCookie = decodeURIComponent(document.cookie);
            const cookies = decodedCookie.split(";");
            for (let cookie of cookies) {
                while (cookie.charAt(0) === " ") {
                    cookie = cookie.substring(1);
                }
                if (cookie.indexOf(name) === 0) {
                    return decodeURIComponent(cookie.substring(name.length, cookie.length));
                }
            }

            return "";
        }

        return decodeURIComponent(this.context.req.cookies[cookieName] || "");
    }

    /**
     * Set cookie by given parameters
     */
    public setCookie(name: string, value: string, expireDays = 365) {
        const date = new Date();
        date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires = "expires=" + date.toUTCString();
        const cookie = name + "=" + encodeURIComponent(value) + ";" + expires + ";path=/";
        if (isBrowser) {
            document.cookie = cookie;
        } else {
            const existsCookies = (this.context.res.getHeader("Set-Cookie") || []).slice(0);
            existsCookies.push(cookie);
            this.context.res.setHeader("Set-Cookie", existsCookies);
        }
    }
}
