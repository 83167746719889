import { IconSizeEnum } from "@config/icons";
import { createSocialLoginUrl } from "@shared/utils/create-social-login-url";
import { useTranslation } from "@translations/use-translation";
import { Button } from "@ui/Button";
import { CircleButton } from "@ui/CircleButton";
import { FlexBox } from "@ui/FlexBox";
import { Icon } from "@ui/Icon";
import { TextLarge } from "@ui/Text";
import { CssHelper } from "@utils/CssHelper";
import { SocialButtonType } from "./types";

interface GoogleLoginButtonsProps {
    title?: string;
    onlyType?: SocialButtonType;
    redirectUrl?: string;
}

export function GoogleLoginButtons(props: GoogleLoginButtonsProps) {
    const { t } = useTranslation();
    const { onlyType, title } = props;

    return (
        <>
            {onlyType !== "circle" && (
                <FlexBox
                    flexDirection="column"
                    permanentlyHidden={!onlyType ? { condition: true, from: "lg" } : undefined}
                >
                    <Button
                        data-testid="google-login-btn"
                        href={createSocialLoginUrl("google", props.redirectUrl)}
                        kind="white"
                        size="large"
                        startComponent={<Icon name="google" size={IconSizeEnum.large} />}
                        variant="text"
                    >
                        {title ?? t("component-auth-login-buttons-google-login:button")}
                    </Button>
                </FlexBox>
            )}
            {onlyType !== "basic" && (
                <FlexBox
                    alignItems="center"
                    flexDirection="column"
                    permanentlyHidden={!onlyType ? { condition: true, to: "lg" } : undefined}
                >
                    <CircleButton
                        data-testid="google-login-btn"
                        href={createSocialLoginUrl("google", props.redirectUrl)}
                        name="google"
                        size="size56"
                        variant="white"
                    />
                    <TextLarge mt={CssHelper.spacing(1)} textColor="textMuted" textFontWeight="medium">
                        {title ?? t("component-auth-login-buttons-google-login:text")}
                    </TextLarge>
                </FlexBox>
            )}
        </>
    );
}
