import { routeToUrl } from "@app-routes";
import { ILoginForm } from "@components/AuthZone/Login/LoginForm";
import { setLastLoggedUserToCookie } from "@components/AuthZone/authHelper";
import { useLoginMutation } from "@hooks/mutations/useLoginMutation";
import { ErrorService } from "@services/ErrorService";
import { UrlHelper } from "@utils/UrlHelper";
import { queryParamToString } from "@uxf_base/helpers/NextContextQueryHelper";
import { useRouter } from "next/router";
import { useCallback } from "react";

export const useEmailLoginDone = () => {
    const [loginMutation, { loading }] = useLoginMutation();
    const router = useRouter();
    const redirUrl = queryParamToString(router.query.redirUrl);

    const loginFormSubmitHandler = useCallback(
        async (data: ILoginForm) => {
            try {
                const response = await loginMutation({ username: data.email, password: data.password });
                const avatar = UrlHelper.getImageUrl(response.data?.login.me.avatar ?? null);
                setLastLoggedUserToCookie(
                    response.data?.login.me.fullName ?? "",
                    response.data?.login.me.email ?? "",
                    "email",
                    avatar ?? undefined,
                );
                const registrationCompleted = response.data?.login.me.registrationCompleted;
                if (registrationCompleted) {
                    if (redirUrl) {
                        window.location.href = redirUrl;
                    } else {
                        router.push(routeToUrl("house-zone/house-community"));
                    }
                } else {
                    router.push(routeToUrl("profile-zone/complete-registration", { redirUrl }));
                }
            } catch (e) {
                ErrorService.handleError(e);
            }
        },
        [loginMutation, redirUrl, router],
    );

    return { loginFormSubmitHandler, loading };
};
