import { UxfNextPageContext } from "@app-types/next";
import { SocialAuthType } from "@gql-schema";
import { useTranslation } from "@translations/use-translation";
import Cookie from "@uxf_base/utils/cookie";
import { isEmail } from "@uxf_base/utils/validator";
import { useCallback } from "react";

export function usePasswordValidator() {
    const { t } = useTranslation();

    return useCallback(
        (value?: string): string | undefined => (value ? undefined : t("common:validate.empty-password")),
        [t],
    );
}

export function useEmailValidator() {
    const { t } = useTranslation();

    return useCallback(
        (value?: string): string | undefined => (!value || isEmail(value) ? undefined : t("common:validate.email")),
        [t],
    );
}

export function usePhoneNumberValidator() {
    const { t } = useTranslation();

    return useCallback(
        (value?: string): string | undefined =>
            value && /^(\+\d{1,3})?(\s*\d){9}$/.test(value) ? undefined : t("common:validate.phone"),
        [t],
    );
}

const isValidPasswordStrength = (value: string): boolean => {
    const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");

    return regex.test(value);
};

export const passwordStrengthValidator = (value?: string): string | undefined =>
    value && isValidPasswordStrength(value)
        ? undefined
        : "Heslo musí obsahovat alespoň jedno malé písmeno, velké písmeno a číslici.";

export const setLastLoggedUserNameToCookie = (lastLoggedUserName: string): void => {
    const cookie = new Cookie();
    cookie.setCookie("LastLoggedUserName", lastLoggedUserName, 365);
};

export const setLastLoggedUserAvatarToCookie = (lastLoggedUserAvatar: string): void => {
    const cookie = new Cookie();
    cookie.setCookie("LastLoggedUserAvatar", lastLoggedUserAvatar, 365);
};

export const getLastLoggedUserNameFromCookie = (ctx: UxfNextPageContext): string => {
    const cookie = new Cookie(ctx);
    return cookie.getCookie("LastLoggedUserName");
};

export const setLastLoggedEmailToCookie = (lastLoggedEmail: string): void => {
    const cookie = new Cookie();
    cookie.setCookie("LastLoggedEmail", lastLoggedEmail, 365);
};

export const getLastLoggedEmailFromCookie = (ctx: UxfNextPageContext): string => {
    const cookie = new Cookie(ctx);
    return cookie.getCookie("LastLoggedEmail");
};

export const getLastLoggedUserAvatarFromCookie = (ctx: UxfNextPageContext): string => {
    const cookie = new Cookie(ctx);
    return cookie.getCookie("LastLoggedUserAvatar");
};

export const setLastLoginMethodToCookie = (lastLoginMethod: SocialAuthType | "email" | ""): void => {
    let selectedMethod;
    switch (lastLoginMethod) {
        case SocialAuthType.Google:
            selectedMethod = "Google";
            break;
        case SocialAuthType.Apple:
            selectedMethod = "Apple";
            break;
        case SocialAuthType.Facebook:
            selectedMethod = "Facebook";
            break;
        case "email":
            selectedMethod = "Email";
            break;
        default:
            selectedMethod = "";
            break;
    }
    if (selectedMethod) {
        const cookie = new Cookie();
        cookie.setCookie("LastLoginMethod", selectedMethod, 365);
    }
};

export const getLastLoginMethodFromCookie = (ctx: UxfNextPageContext): string => {
    const cookie = new Cookie(ctx);
    return cookie.getCookie("LastLoginMethod");
};

export const setLastLoggedUserToCookie = (
    lastLoggedUserName: string,
    lastLoggedEmail: string,
    lastLoginMethod: SocialAuthType | "email",
    lastLoggedUserAvatar?: string,
): void => {
    setLastLoggedUserNameToCookie(lastLoggedUserName);
    setLastLoggedEmailToCookie(lastLoggedEmail);
    setLastLoginMethodToCookie(lastLoginMethod);
    if (lastLoggedUserAvatar) {
        setLastLoggedUserAvatarToCookie(lastLoggedUserAvatar);
    }
};

export const resetLastLoggedUserToCookie = (): void => {
    setLastLoggedUserNameToCookie("");
    setLastLoggedEmailToCookie("");
    setLastLoginMethodToCookie("");
    setLastLoggedUserAvatarToCookie("");
};
