/** @see https://emailregex.com/ - synced with BE 16. 10. 2018 */
const NO_DIACRITICS_EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.+[a-zA-Z]{2,})$/; // TODO is this ok?

export const isEmail = (email: string): boolean => NO_DIACRITICS_EMAIL_REGEXP.test(email);

export const isValidPasswordLength = (password: string) => password.length > 5;

// validation functions
export const isPhoneNumberLengthValid = (value: string): boolean => {
    return /^\d{9}$/.test(value);
};

export const isFirstNumberValid = (value: string): boolean => {
    return /^([6,7])/.test(value);
};

export const isPhoneNumberValid = (value: string): boolean => {
    // with prefix: return /^(\+420)?\s*([6,7])(\s*\d){8}$/.test(value);
    return /^([6,7])(\s*\d){8}$/.test(value); // mobile phone number without prefix
};

export const validURL = (str: string): boolean => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i",
    ); // fragment locator
    return !!pattern.test(str);
};

export const getValidURLFormat = (url: string) => {
    const pattern = /^((http|https|ftp):\/\/)/;
    if (!pattern.test(url)) {
        url = "http://" + url;
    }
    return url;
};

export default class Validator {
    public static isEmail = isEmail;
    public static isValidPasswordLength = isValidPasswordLength;
    public static isPhoneNumberLengthValid = isPhoneNumberLengthValid;
    public static isFirstNumberValid = isFirstNumberValid;
    public static isPhoneNumberValid = isPhoneNumberValid;
}
