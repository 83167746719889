import { routeToUrl } from "@app-routes";
import LoginForm from "@components/AuthZone/Login/LoginForm";
import { useEmailLoginDone } from "@hooks/login/useEmailLoginDone";
import { useTranslation } from "@translations/use-translation";
import { Avatar } from "@ui/Avatar";
import { Button } from "@ui/Button";
import { FlexBox } from "@ui/FlexBox";
import { GhostButton } from "@ui/GhostButton";
import { Paper } from "@ui/Paper";
import { Text, TextH4 } from "@ui/Text";
import { spacing } from "@uxf/styles/units/spacing";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { resetLastLoggedUserToCookie } from "../authHelper";
import { AppleLoginButtons } from "./Buttons/AppleLoginButtons";
import { FacebookLoginButtons } from "./Buttons/FacebookLoginButtons";
import { GoogleLoginButtons } from "./Buttons/GoogleLoginButtons";

interface LoginWelcomeBackProps {
    lastLoggedUserName: string;
    lastLoggedEmail: string;
    lastLoginMethod: string;
    lastLoggedUserAvatar: string;
}

export const LoginWelcomeBackComponent: FC<LoginWelcomeBackProps> = (props) => {
    const { t } = useTranslation();
    const { lastLoggedUserName, lastLoggedEmail, lastLoginMethod, lastLoggedUserAvatar } = props;
    const router = useRouter();
    const { loginFormSubmitHandler, loading } = useEmailLoginDone();

    const resetLogin = () => {
        resetLastLoggedUserToCookie();
        router.push(routeToUrl("auth-zone/login", {}));
    };

    return (
        <>
            <Text
                mb={spacing(2)}
                as="h1"
                textAlign="center"
                textFontWeight="medium"
                textVariant={["h2", null, null, "h2"]}
            >
                {t("component-auth-login-welcome-back:text")}
            </Text>
            <Paper>
                <FlexBox pl={spacing(2)} pr={spacing(2)} pb={spacing(2)} alignItems="stretch" flexDirection="column">
                    <FlexBox pt={spacing(2)} justifyContent="flex-end">
                        <GhostButton name="cross" onClick={resetLogin} />
                    </FlexBox>
                    <FlexBox justifyContent="center">
                        <Avatar mb={spacing(2)} mt={spacing(1)} src={lastLoggedUserAvatar} size={"size70"} />
                    </FlexBox>
                    <TextH4 textAlign="center" mb={spacing(4)}>
                        {lastLoggedUserName}
                    </TextH4>
                    {lastLoginMethod === "Email" && (
                        <LoginForm
                            onSubmit={loginFormSubmitHandler}
                            mutationLoading={loading}
                            initialValues={{ email: lastLoggedEmail }}
                            hideEmail
                        />
                    )}
                    {lastLoginMethod === "Google" && (
                        <GoogleLoginButtons
                            onlyType="basic"
                            title={t("component-auth-login-welcome-back:login-method.google")}
                        />
                    )}
                    {lastLoginMethod === "Facebook" && (
                        <FacebookLoginButtons
                            onlyType="basic"
                            title={t("component-auth-login-welcome-back:login-method.facebook")}
                        />
                    )}
                    {lastLoginMethod === "Apple" && (
                        <AppleLoginButtons
                            onlyType="basic"
                            title={t("component-auth-login-welcome-back:login-method.apple")}
                        />
                    )}
                    <Button mt={spacing(1)} size="large" kind="text" variant="textMuted" onClick={resetLogin}>
                        {t("component-auth-login-welcome-back:button")}
                    </Button>
                </FlexBox>
            </Paper>
        </>
    );
};

export const LoginWelcomeBack = memo(LoginWelcomeBackComponent);

LoginWelcomeBack.displayName = "LoginWelcomeBack";
