import { AppleLoginButtons } from "@components/AuthZone/Login/Buttons/AppleLoginButtons";
import { GoogleLoginButtons } from "@components/AuthZone/Login/Buttons/GoogleLoginButtons";
import { FlexBox } from "@ui/FlexBox";

interface LoginSocialProps {
    redirectUrl?: string;
}

export function LoginSocial(props: LoginSocialProps) {
    return (
        <FlexBox flexDirection={["column", null, null, "row"]}>
            <GoogleLoginButtons redirectUrl={props.redirectUrl} />
            <AppleLoginButtons redirectUrl={props.redirectUrl} />
        </FlexBox>
    );
}
